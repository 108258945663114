import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="About us" />
    <h1>ABOUT US</h1>
    <p>
      COMING SOON</p>
      <p>Best, <br/>Ewa & Chris</p>

      <p>Imprint:<br/>
      Christian Auzinger<br/>
      Greinergasse 6<br/>
      1190 Vienna / Austria</p>
      <p>Please send questions, suggestions, help requests and other info (NO SPAM) to info@easysolutions.cc</p>
      <Link to="/DrillSergeant/terms">Terms</Link> <br/>
    <Link to="/DrillSergeant/privacy">Privacy</Link> 
  </Layout>
)

export default IndexPage